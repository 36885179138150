import { Controller } from "@hotwired/stimulus"
// import { randomInt, randomFloat } from "./../canvas/helpers.js"

export default class extends Controller {

    // static targets = []

    connect() {
        window.addEventListener('load', () => {
            this.resizeBoxesToGrid()
          })

        window.addEventListener("resize", () => {
            this.resizeBoxesToGrid()
        })
    }

    resizeBoxesToGrid() {
        console.log("resizeBoxesToGrid")
        let elements = [
            ...document.getElementsByClassName("box-fit-to-grid"), 
            ...document.getElementsByClassName("section"),
            ...document.getElementsByClassName("section-header")
        ]
        elements = elements.sort((a, b) => this.getAncestors(a).length - this.getAncestors(b).length)
        for (let e of elements.reverse()) {
            const remainder = e.clientHeight % 120
    
            if (remainder > 0) {
                e.style.height = ``
                e.style.height = `${e.clientHeight + (120 - remainder)}px`
            }
        }
    }

    getAncestors(el) {
        let ancestors = []
        while (el) {
            ancestors.unshift(el)
            el = el.parentNode
        }
        return ancestors
    }
}
import { Controller } from "@hotwired/stimulus"
import { randomInt, randomFloat } from "./canvas/helpers.js"

class Grid {
    constructor(context, canvas) {
        this.canvas = canvas
        this.context = context

        this.backgroundColour = "rgb(250, 250, 250)"

        this.crosshairColour = "rgba(0, 0, 0, 0.2)"
        this.crosshairSize = 6
        this.crosshairwidth = 1
        
        this.gridLineColour = "rgba(0, 0, 0, 0.02)"
        this.gridLineWidth = 1

        this.gridSpacing = 120
        this.gridLinePadding = 0

        this.setup()
    }

    setup() {
        this.points = new Array();
  
        var x = 0;
        var y = 0;

        while((y - this.gridSpacing) < this.context.canvas.height) {
            while((x - this.gridSpacing) < this.context.canvas.width) {
                this.points.push({"x": x, "y": y});
                x = x + this.gridSpacing;
            }
            x = 0;
            y = y + this.gridSpacing;
        }
    }

    draw() {
        this.setup()
        this.context.fillStyle = this.backgroundColour
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)

        //this.drawGridSquares()
        this.drawGridLines()
        this.drawCrosshairs()
    }

    drawGridSquares() {
        this.points.forEach((point) => {
            this.context.fillStyle = `rgba(${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomFloat(0, 0.1)})`
            this.context.fillRect(point.x, point.y, this.gridSpacing, this.gridSpacing)
        })
    }

    drawGridLines() {
        this.points.forEach((point) => {
            this.context.save()

            this.context.beginPath()

            this.context.translate(point.x, point.y)

            this.context.lineWidth = this.gridLineWidth
            this.context.strokeStyle = this.gridLineColour

            this.context.moveTo(this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridLinePadding)

            this.context.closePath()
            this.context.stroke()

            this.context.restore()
        })
    }

    drawCrosshairs() {
        this.points.forEach((point) => {
            this.context.save()
            this.context.translate(point.x, point.y)
            
            this.context.beginPath()
            this.context.strokeStyle = this.crosshairColour
            this.context.lineWidth = this.crosshairwidth
    
            this.context.moveTo(0, this.crosshairSize)
            this.context.lineTo(0, 0 - this.crosshairSize)
    
            this.context.moveTo(this.crosshairSize, 0)
            this.context.lineTo(0 - this.crosshairSize, 0)
    
            this.context.closePath()
            this.context.stroke()
            
            this.context.restore()
        })
    }

}

export default class extends Controller {

    static targets = [ "canvas" ]

    connect() {
        this.canvas = this.canvasTarget
        this.canvas.width  = document.body.clientWidth
        this.canvas.height = document.body.clientHeight + 300

        this.context = this.canvas.getContext("2d")

        this.grid = new Grid(this.context, this.canvas)

        window.addEventListener("load", () => {
            console.log("grid load")

            // Hack to ensure the grid is drawn after the box layout has been calculated
            setTimeout(() => { this.draw()}, 1)
        })

        window.addEventListener("resize", () => {
            console.log("grid resize")
            this.draw()
        })
    }

    draw() {
        this.canvas.width  = document.body.clientWidth
        this.canvas.height = Math.max(document.body.clientHeight, window.innerHeight)

        this.grid.context = this.canvas.getContext("2d")
        this.grid.draw()
    }
}